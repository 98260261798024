<template>
  <div class="main">
    <Carousel :lang="lang" />

    <div v-if="lang === 'it'" class="frase container text-center mt-5 mb-5">
      <h1>”</h1>
      <h3>Perché sia Musica deve riuscire a penetrare la corazza d'indifferenza dell'ascoltatore e colpirlo direttamente al cuore. <br>
Solo un bisogno espressivo vero e urgente può sperare di riuscirci. <br>
Il resto è un ammasso inutile di suoni nell'aria</h3>
      <h1>“</h1>
      <p class="text-right"><i>Luca Garlaschelli</i></p>
    </div>

    <div v-else class="frase container text-center mt-5 mb-5">
      <h1>”</h1>
      <h3>Music, to be Music, must succeed in piercing the armor of the listener's indifference and strike him directly to the heart.   <br>
Only a real need to express oneself may have the chance to succeed.<br>All the rest is just a useless bulk of sounds in the air.</h3>
      <h1>“</h1>
      <p class="text-right"><i>Luca Garlaschelli</i></p>
    </div>

      <div v-if="lang === 'it'" class="frase container mt-5 mb-5 d-flex flex-wrap">
        <div
        v-for="(prog, i) in progects"
        :key="i"
        class="box-proj p-3 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
          <div class="inner">
              <h3>{{ prog.titolo }}</h3>
              <a
                :href="prog.url"
              ><img :src="require(`@/assets/images/progetti/${prog.top_img}`)" :alt="prog.titolo"></a>
              
              <p v-html="prog.descrizione.substr(0, 190)+'...'"> </p>
              <a
                :href="prog.url"
              class="btn btn-secondary">Scopri di più</a>
              </div>
        </div>
      </div>

      <div v-else class="frase container mt-5 mb-5 d-flex flex-wrap">
        <div
        v-for="(prog, i) in progects_en"
        :key="i"
        class="box-proj p-3 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
          <div class="inner">
              <h3>{{ prog.titolo }}</h3>
              <a
                :href="prog.url"
              ><img :src="require(`@/assets/images/progetti/${prog.top_img}`)" :alt="prog.titolo"></a>
              
              <p v-html="prog.descrizione.substr(0, 190)+'...'"> </p>
              <a
                :href="prog.url+'_en'"
              class="btn btn-secondary">Read more...</a>
              </div>
        </div>
      </div>

      
 


  </div>
</template>

<script>
import Carousel from '@/components/Carousel';
import progects from '@/assets/data/progetti_it.js';
import progects_en from '@/assets/data/progetti_en.js';
export default {
  name: 'Home',
  components:{
    Carousel
  },
  props:{
    lang:String
  },
  data(){
    return{
      progects,
      progects_en
    }
  },
  methods:{
   
  }
}
</script>

<style lang="scss" scoped>
.main{
  margin-top: 55px;
}
.box-proj{
  min-height: 300px;
  .inner{
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 0px 4px rgba(0,0,0,.2);
    padding: 20px;
    transition: all 0.3s;
    p{
      padding-top: 10px;
      hr{
        color:white;
      }
    }
    img{
      width: 100%;
    }
    &:hover{
      transform: scale(1.01,1.01);
      box-shadow: 0 0px 10px rgba(0,0,0,.2);
    }
    
  }
  
}
</style>