import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import progects from '@/assets/data/progetti_it.js';
import progects_en from '@/assets/data/progetti_en.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Home,
    props: { default: true, lang:'it'}
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: { default: true, lang:'it'}
  },
  {
    path: '/home-eng',
    name: 'home-eng',
    component: Home,
    props: { default: true, lang:'en'}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    props: { default: true, lang:'it'}
  },
  {
    path: '/about-eng',
    name: 'about-eng',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    props: { default: true, lang:'eng'}
  },
  {
    path: '/discs',
    name: 'discs',
    component: () => import( '../views/Discografia.vue'),
    props: { default: true, lang:'it'}
  },
  {
    path: '/discs-eng',
    name: 'discs-eng',
    component: () => import( '../views/Discografia.vue'),
    props: { default: true, lang:'en'}
  },
  {
    path: '/rassegna',
    name: 'rassegna',
    component: () => import( '../views/Rassegna.vue')
  },
  {
    path: '/duology',
    name: 'duology',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:0, lang:'it'}
  },
  {
    path: '/musicaevisioni',
    name: 'musicaevisioni',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:1, lang:'it'}
  },
  {
    path: '/musikorchestra',
    name: 'musikorchestra',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:2, lang:'it'}
  },
  {
    path: '/magellano',
    name: 'magellano',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:3, lang:'it'}
  },
  {
    path: '/mingus-in-strings',
    name: 'mingus-in-strings',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:4, lang:'it'}
  },
  {
    path: '/rapsodia',
    name: 'rapsodia',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:5, lang:'it'}
  },
  {
    path: '/resistenza',
    name: 'resistenza',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:6, lang:'it'}
  },
  {
    path: '/verdi',
    name: 'verdi',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:7, lang:'it'}
  },
  {
    path: '/duology_en',
    name: 'duology_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:0, lang:'en'}
  },
  {
    path: '/musicaevisioni_en',
    name: 'musicaevisioni_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:1, lang:'en'}
  },
  {
    path: '/musikorchestra_en',
    name: 'musikorchestra_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:2, lang:'en'}
  },
  {
    path: '/magellano_en',
    name: 'magellano_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:3, lang:'en'}
  },
  {
    path: '/mingus-in-strings_en',
    name: 'mingus-in-strings_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:4, lang:'en'}
  },
  {
    path: '/rapsodia_en',
    name: 'rapsodia_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:5, lang:'en'}
  },
  {
    path: '/resistenza_en',
    name: 'resistenza_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:6, lang:'en'}
  },
  {
    path: '/verdi_en',
    name: 'verdi_en',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:7, lang:'en'}
  },
  {
    path: '/progetti',
    name: 'progetti',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects, id:0, lang:'it'}
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import( '../views/Project.vue'),
    props: { default: true, progects,  progects_en, id:0, lang:'en'}
  },
  {
    path: '/contatti',
    name: 'contatti',
    component: () => import( '../views/Contacts.vue'),
    props: { default: true, lang:'it'}
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import( '../views/Contacts.vue'),
    props: { default: true, lang:'en'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  props:{
    test:String
  }
})

export default router
