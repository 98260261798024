<template>
    <header :class="{'fullH' : isFull}">
        <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">LUCA GARLASCHELLI</a>

            <button 
            @click="isFull = !isFull"
            class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

           

            <div class="collapse navbar-collapse" id="navbarCollapse">
              <ul class="navbar-nav me-auto mb-2 mb-md-0">
               
              </ul>
              <form class="d-flex">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">

                  <li 
                  v-for="(voce, i) in menu[idLang]"
                  :key="i + 'a'"
                   class="nav-item">
                    <a class="nav-link" 
                    @click.prevent="goto(i)"
                    :href="`/${voce.href}`">{{ voce.label }}</a>
                  </li> 

                  <li v-if="!idLang" @click="changeLang(1)" class="nav-item"><a class="nav-link" href="javascript:void(0)">
                    <img src="@/assets/images/flags/en.png" alt="english">
                    </a></li>
                  <li v-else @click="changeLang(0)" class="nav-item"><a class="nav-link" href="javascript:void(0)">
                    <img src="@/assets/images/flags/it.png" alt="italiano">
                    </a></li>
                 
                </ul>
              </form>
            </div>
          </div>
        </nav>

         <div v-if="isFull" class="mobile-nav" >
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                   <li 
                  v-for="(voce, i) in menu[idLang]"
                  :key="i + 'b'"
                   class="nav-item">
                    <a class="nav-link" 
                    @click.prevent="goto(i)"
                    :href="`/${voce.href}`">{{ voce.label }}</a>
                  </li> 

                  <li v-if="!idLang" @click="changeLang(1)" class="nav-item"><a class="nav-link" href="javascript:void(0)">
                    <img src="@/assets/images/flags/en.png" alt="english">
                    </a></li>
                  <li v-else @click="changeLang(0)" class="nav-item"><a class="nav-link" href="javascript:void(0)">
                    <img src="@/assets/images/flags/it.png" alt="italiano">
                    </a></li>

                </ul>
            </div>

      </header>
</template>

<script>
export default {
  name:'Haeder',
  data(){
    return{
      isFull: false,
      idLang:0,
      idItem:0,
      menu:[
        [
          {
            href:'',
            label:'HOME'
          },
          {
            href:'about',
            label:'CHI SONO'
          },
          {
            href:'discs',
            label:'DISCOGRAFIA'
          },
          {
            href:'rassegna',
            label:'RASSEGNA STAMPA'
          },
          {
            href:'progetti',
            label:'PROGETTI'
          },
          {
            href:'contatti',
            label:'CONTATTI'
          },
        ],
        [
          {
            href:'home-eng',
            label:'HOME'
          },
          {
            href:'about-eng',
            label:'ABOUT ME'
          },
          {
            href:'discs-eng',
            label:'DEISCOGRAPHY'
          },
          {
            href:'rassegna',
            label:'PRESS REVIEW'
          },
          {
            href:'projects',
            label:'PROJECTS'
          },
          {
            href:'contacts',
            label:'CONTACTS'
          },
        ]
      ]
    }
  },
  methods:{
    changeLang(id){
      this.idLang = id;
      this.redirect();
    },
    goto(id){
      this.idItem = id;
      this.redirect();
    },
    redirect(){
      let nameRout = 'home';
      if(this.menu[this.idLang][this.idItem].href !== ''){
        nameRout = this.menu[this.idLang][this.idItem].href;
      }
      console.log(nameRout);
      this.$router.push(nameRout); 
    }
  }

}
</script>

<style lang="scss" scoped>
header.fullH{
  padding-left: 5%;
  height: 100vh;
    background-color: #202020;
}
.mobile-nav{

  margin-top: 55px;
  padding-top: 20px;
    a{
      color:white
    }
}
img{
  width: 20px;
  vertical-align: middle;
  display:inline-block;
  margin-bottom: 2px;
}

@media all and (min-width: 768px) { 
    header.fullH{
    height: 0px;
    
  }
}

</style>