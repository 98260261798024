<template>
  <div id="app">
    <Haeder />
    <router-view test="ciao"/>
    <footer>
      <div>
        lucagarlaschelli.com - all right reserved
      </div>
    </footer>
  </div>
</template>

<script>
  import Haeder from '@/components/Haeder';
  export default{
    components:{
      Haeder
    },
    data(){
      return{

      }
    }
  }
</script>

<style lang="scss">

@import './assets/styles/general';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
footer{
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  background-color: #202020;
  color: #aaa;
  font-size: .7rem;
}
</style>
