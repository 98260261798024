<template>

  <div>

      <vueper-slides fade :touchable="false">
        <vueper-slide
          v-for="(slide, i) in slides"
          :key="i"
          :image="slide.image"
          :title="slide.title"
          :content="slide['content_'+lang]" />
      </vueper-slides>

  </div>
  
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'



export default {
  name: 'Carousel',
  components: { VueperSlides, VueperSlide },
  props:{
    lang:String
  },
  data: () => ({
    pauseOnHover: true,
    autoPlaying: true,
    internalAutoPlaying: true,
    slides: [
      {
        title: 'Luca Garlaschelli',
        content_it: 'Musicista',
        content_en: 'Musician',
        // You can also provide a URL for the image.
        image: require('@/assets/images/slider/Luca-Garlaschelli-.jpg')
      },
      {
        title: 'Luca Garlaschelli',
        content_it: 'Insengnante',
        content_en: 'Teacher',
        // You can also provide a URL for the image.
        image: require('@/assets/images/slider/Luca-Garlaschelli-bloom.jpg')
      },
      {
        title: 'Luca Garlaschelli',
        content_it: 'Compositore',
        content_en: 'Composer',
        // You can also provide a URL for the image.
        image: require('@/assets/images/slider/Luca-Garlaschelli-mantova.jpg')
      },
      {
        title: 'Luca Garlaschelli',
        content_it: 'Contrabbassista',
        content_en: 'Double bass player',
        // You can also provide a URL for the image.
        image: require('@/assets/images/slider/Luca-Garlaschelli-spirit.jpg')
      },
      // Other slides.
    ],
    colors: ['red','blue','yellow','green']
  })
}
</script>

<style lang="scss" >

.vueperslide__title {
  position: absolute;
   top:60%;
   left: 20%;
   font-size:2rem;
   color: white;
}
.vueperslide__content{
  position: absolute;
   top:calc(60% + 45px);
   left: 20%;
   font-size:1rem;
   color: white;
}

</style>